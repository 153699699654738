.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;

  transition: all 0.2s ease-out;

  &-inactive {
    opacity: 0;
  }

  .pace-progress {
    background: #fff;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 0px;
  }
}