// SCSS Vars
$bp-desk: 1032px;
$bp-lap: 850px;
$bp-tab: 700px;
$bp-mob: 500px;

// CSS Vars
:root {
  --color-bg: #111;

  --spacing-sideGap: 50px;

  --container-width: #{$bp-desk};

  --grid-col-amount: 6;
  --grid-col: calc(var(--container-width) / var(--grid-col-amount));

  --color-band: var(--color-red);
  --color-bands-section1: #222; // Grey - Black bg
  --color-bands-section2: #e2141d; // Red
  --color-bands-section3: #222; // Grey - Black bg
  --color-bands-section4: #222; // Grey - Black bg
}


[data-page="landing"] {
  --font-content: "museo-slab", #{$font-stack};
  --color-selected-text: #fff;
  --color-selected-background: rgba(0,0,0,0.8);

  ::selection {
    background-color: var(--color-selected-background); /* WebKit/Blink Browsers */
    color: var(--color-selected-text);
  }
  ::-moz-selection {
    background-color: var(--color-selected-background); /* Gecko Browsers */
    color: var(--color-selected-text);
  }



  .main-container {
    position: absolute;
    height: 100%;
    width: 100%;
  }



  .background-band {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns:
      1fr
      repeat(var(--grid-col-amount), minmax(auto, var(--grid-col)))
      1fr;
    grid-template-rows: 1fr;
    justify-items: end;

    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    .band {
      position: relative;
      background-color: var(--color-band);
      //border-left: 1px solid var(--color-line);
      top: -100%;
      width: calc(100% - 1px);
      box-sizing: content-box;
    }
  } // .background-band

  .section:not([data-section='1']) .background-band .band { width: 1px; }





  .section {
    overflow: hidden;
    position: relative;
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns:
      1fr
      repeat(var(--grid-col-amount), minmax(auto, var(--grid-col)))
      1fr;
    padding: calc(var(--spacing-xxl) * 3) 0;

    font-family: var(--font-content);

    &--red { background-color: var(--color-red); }
    &--blue { background-color: var(--color-blue);}
    &--white {
      background-color: var(--color-bgWhite);
      color: var(--color-textLightMain);
    }



    // Init, only display first section
    &:not([data-section='1']),
    &[data-section='1'] > [class^='section__'] {
      display: none;
      opacity: 0;
      user-select: none;
      will-change: opacity, transform;
    }





    &__title,
    &__sub-title,
    &__article {
      position: relative; // over the grid lines
      grid-column: 2 / -2;
    }


    // Titles
    &__title {
      font-size: 1.5rem;

      &--deco {
        font-weight: 700;
        font-size: 10rem;
      }
    }



    &__article {
      --para-color: var(--color-textMain);
      --para-color-light: var(--color-textMainLight);
      --para-color-deco: var(--color-textMainLight);

      .article__para {
        font-size: 1rem;
        color: var(--para-color);
        font-weight: 100;

        &:first-child {
          padding-top: 0;
          margin-top: 0;
        }
        &:last-child { margin-bottom: 0; }

        i { color: var(--para-color-light); }

        span,
        strong {
          font-weight: 700;
        }
        abbr { text-decoration: none; }
        small {
          font-family: var(--font-content);
          opacity: 0.4;
          font-size: 0.4rem;
        }

        a {
          --link-color: #000;
          --link-color-active: #fff;
          --link-border-bottom: rgba(0,0,0,0.8);

          text-decoration: none;
          display: inline-block;
          position: relative;
          color: var(--link-color);

          &:active {
            transition: none;
            background: var(--link-color);
            color: var(--link-color-active);
            &:before { display: none; }
          }
        }

        .highlight {
          display: inline;
          padding: 0.5rem 0;
          background-color: rgba(0,0,0,0.8);
          font-weight: 100;
          letter-spacing: 0.02rem;
        }

        span.js-imageOverlay {
          display: inline;
          color: var(--para-color-deco);
          text-decoration: underline;
        }
      } // .article__para
    }


    // Intro
    &[data-section='1'] {
      min-height: 100vh;
      padding-top: calc(var(--spacing-xxl) * 4);

      .section__title {
        font-weight: 700;
        font-size: 10rem;
        color: var(--color-red);
      }
      .section__sub-title { margin-left: calc(var(--grid-col) * 2); }
      .section__article {
        --para-color-deco: var(--color-red);

        margin-top: var(--spacing-xl);
        grid-column: 4 / -2;
        z-index: 1; // deal with ninjaNumber

        .article__para { font-weight: 100; }
        i {
          background-color: var(--para-color-deco);
          font-weight: 300;
        }
        a {
          display: inline;
          color: #fff;
          font-weight: 500;
          border-bottom: 1px dotted rgba(255,255,255,0.6);
        }
      }
    }


    // Presentation
    &[data-section='2'] {
      padding-top: calc(var(--spacing-xxl) * 2);
      padding-bottom: calc(var(--spacing-xl) * 2);
      .article__image img { max-width: calc((var(--grid-col) * 2) + var(--spacing-m)); }
    }
    &[data-section='3'] {
      padding-top: calc(var(--spacing-l) * 2);
      padding-bottom: calc(var(--spacing-xl) * 2);
      .article__image img {
        max-width: calc((var(--grid-col) * 4) + var(--spacing-m));
        margin-bottom: var(--spacing-m);
      }
    }

    &[data-section='2'],
    &[data-section='3'] {
      .section__article {
        display: grid;
        grid-template-columns: repeat(var(--grid-col-amount), minmax(auto, 1fr));

        .article__image {
          grid-column: 1 / -1;
          position: relative;

          img {
            display: block;
            margin-right: auto;
            margin-left: auto;
            border: var(--spacing-s) solid #000;
          }

          figcaption {
            text-align: center;
            margin-bottom: var(--spacing-s);
            display: block;
            font-family: var(--font-titles);
            font-weight: 500;
            font-size: 2rem;
            line-height: 1;
            //background: var(--color-mainBg);
            color: var(--color-textMainLight);
          }
        }

        .article__para {
          position: relative;
          grid-column: 2 / -2;
          color: var(--color-textMainLight);

          font-size: 1rem;
          // background-color: var(--color-mainBg);

          &:first-of-type { margin-top: var(--spacing-xs); }

          a {
            display: inline;
            color: #fff;
            font-weight: 500;
            border-bottom: 1px dotted rgba(255,255,255,0.6);
          }
        } // .article__para
      }
    } // &[data-section='2']





    // Black standard
    &[data-section='4'] {
      padding: calc(var(--spacing-l) * 2) 0;

      .section__article {
        display: grid;
        grid-template-columns: repeat(var(--grid-col-amount), minmax(auto, 1fr));

        .article__para {
          grid-column: 2 / -2;
          margin-bottom: 0;
          font-weight: 100;
          color: #fff;

          a {
            --link-color: #fff;
            --link-color-active: #000;
            --link-border-bottom: rgba(255,255,255,0.8);
            display: inline;
            border-bottom: 1px dotted var(--link-border-bottom);
            font-weight: 300;

            &:before { display: none; }
          }
        }
      }
    } // &[data-section='4']




    // Presentation
    &[data-section='5'] {
      padding: var(--spacing-xxl) 0;

      border-top: 1px solid var(--color-bands-section4);
      border-bottom: 1px solid var(--color-bands-section4);
      align-items: center;


      .section__title {
        grid-column: 2 / 4;
        padding-top: 0;
        margin-bottom: 0;
        font-family: var(--font-titles);
        font-weight: 400;
        font-size: 2rem;
        line-height: 3rem;
        color: var(--color-yellow);
        z-index: 1;
      }

      .section__article {
        grid-column: 5 / -2;

        .article__para {
          position: relative;
          color: #fff;
          margin-left: var(--spacing-m);
          font-size: 0.8rem;
          line-height: 1.6rem;
          font-weight: 100;
          letter-spacing: 0.03rem;
          z-index: 1;

          em {
            background: var(--color-mainBg);
            color: var(--color-yellow);
            font-style: normal;
            font-weight: 300;
          }

          i { font-style: italic; }
        }
      }

      .background-circles {
        @extend %pulse;
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: 0;
          width: 100%; // pas d'embrouilles
          height: 100%;
          background-color: var(--color-mainBg);
          opacity: .7;
          z-index: 1;
          border-left: 1px solid var(--color-mainBg);
        }
      }
    } // &[data-section='5']




    // Work
    &[data-section='6'] {
      padding:
        calc(var(--spacing-xxl) * 2) 0
        calc((var(--spacing-xxl) * 2) + var(--spacing-m)) 0;
      background-color: var(--color-blue);
      .section__title {
        font-size: 4rem;
        font-weight: 400;
        text-align: center;
        small:first-of-type {
          font-size: 0.5rem;
          opacity: 0.4;
          margin-right: 3px;
        }
        small:last-of-type { opacity: 0.5; }
      }
    }

    &[data-section='7'],
    &[data-section='9'] {
      padding:
        calc(var(--spacing-xxl) * 2)
        0
        calc(var(--spacing-xxl) * 3);

      .background-band .band { --color-band: #222; }

      .section__article {
        grid-column: 2 / -2;
        display: grid;
        grid-template-columns: repeat(var(--grid-col-amount), minmax(auto, 1fr));


        &:not(:first-of-type) {
          margin-top: calc(var(--spacing-xxl) * 2);
        }
      }
    }

    &[data-section='7'] .section__article--prop-ite,
    &[data-section='9'] .section__article--git {
      a:before { display: none; }
      strong, strong a { color: var(--color-yellow); }

      .article__para .social-icon {
        border-bottom: 0;
        i {
          height: 1.7rem;
          width: 1.7rem;
          display: inline-block;
          padding: 0.2rem;
          background-color: var(--color-mainBg);
          text-decoration: none;
          font-style: normal;
          font-size: 1.3rem;
          text-align: center;
          color: var(--color-yellow);
          transition: all 0.1s var(--transi-cubicBezier);
          will-change: background-color, color;
        }

        &:focus i,
        &:hover i {
          background-color: var(--color-yellow);
          color: var(--color-mainBg);
        }
      }
    }


    &[data-section='7'] {
      .section__article {
        &--victor {
          .article__title,
          .article__para {
            grid-column: 2 / -2;
            a:before { display: none; }
          }
          .article__nav {
            grid-column: 3 / 6;
            .nav__button {
              float: left;
              margin-bottom: var(--spacing-s)
            }
          }
        }

        &--prop-ite {
          .article__title,
          .article__para,
          .article__nav { grid-column: 3 / 7; }

          .article__nav .nav__button {
            position: relative;
            text-align: center;
            z-index: 1;

            &:last-child { margin-right: var(--spacing-m); }
          }
        }
      } // .section__article

      .article {
        &__title {
          grid-column: 1 / -1;
          font-size: 2rem;
          font-family: var(--font-titles);
          color: var(--color-yellow);
          font-weight: 400;
        }
        &__para {
          grid-column: 1 / 5;
          font-size: 1rem;
          font-weight: 100;
          a {
            display: inline;
            color: #fff;
            border-bottom: 1px dotted rgba(255,255,255,0.8);
          }
          em {
            opacity: 0.4;
            font-weight: 100;
            font-style: normal;
          }
        }
        &__code {
          grid-column: 1 / -1;
        }

        &__figure {
          grid-column: span 2;
          grid-row: 3 / 4;

          &:not(:first-of-type) { padding-left: var(--spacing-s); }
          &:not(:last-of-type) { padding-right: var(--spacing-s); }

          .figure__video { width: 100%; }
        }

        &__nav { grid-column: 1 / -1; } // &__nav
      } // .article
    } // &[data-section='7']



    // Github
    &[data-section='8'] {
      padding:
        var(--spacing-xxl) 0
        calc(var(--spacing-xxl) + var(--spacing-s)) 0;
      background-color: var(--color-yellow-background);
      .section__title {
        font-size: 4rem;
        text-align: center;
        color: #000;
        font-weight: 500;

        small:first-of-type {
          font-size: 0.5rem;
          opacity: 0.4;
          margin-right: 3px;
        }
        small:last-of-type { opacity: 0.5; }
      }
    } // &[data-section='8']

    &[data-section='9'] {
      padding-top: var(--spacing-xxl);
      .section__article--git {
        .article__title,
        .article__para,
        .article__nav { grid-column: 2 / -2; }

        a { text-decoration: underline; }
      }
    }




    // Summary
    &[data-section='10'] {
      padding:
        calc(var(--spacing-xl) * 2)
        0
        calc(var(--spacing-xxl) * 3);

      border: var(--spacing-xl) solid #fff;
      background-color: var(--color-blue);


      .background-band .band { --color-band: rgba(255,255,255,0.1); }

      .section__title {
        margin-bottom: var(--spacing-m);
        font-size: 8rem;
        line-height: 10rem;
        font-weight: 500;
        color: #fff;
        text-align: center;
      }

      .section__article {
        display: grid;
        grid-template-columns: repeat(var(--grid-col-amount), minmax(auto, 1fr));

        .article__para {
          position: relative;
          grid-column: 2 / -2;
          font-family: var(--font-titles);
          font-size: 1.2rem;
          z-index: 1;

          &, i { color: rgba(255,255,255,0.9); }

          &, span { font-weight: 300; }
          span {
            text-decoration: none;
            font-weight: 500;
            color: #fff;
            sup {
              top: 0;
              color: var(--color-yellow);
            }
          }

          a {
            display: inline;
            color: #fff;
            font-weight: 400;
            text-decoration: underline;
            &:before { display: none; }
          }
        }
      }

    } // &[data-section='10']


  } // .section




  @media only screen and (min-width: #{$bp-lap * 2}) {
    .section[data-section='1'] .section__title { font-size: 9rem; }
  }

  @media only screen and (max-width: #{$bp-desk + 48px}) {
    .section {
      --container-width: #{$bp-lap};
      --grid-col: calc(var(--container-width) / var(--grid-col-amount));

      &[data-section='1'] {
        min-height: auto;
        .section__title,
        .section__sub-title {
          padding-top: 0;
          margin-bottom: 0;
        }
        .section__title {
          font-size: 7rem;
          line-height: 8rem;
        }
        //.section__sub-title { top: calc(var(--spacing-m) * -1); }
        .section__article {
          margin-top: 0;
          grid-column: 3 / -3;
        }
      }
      &[data-section='2'] .section__article {
        //.article__image figcaption { font-size: 3rem; }
        .article__para { grid-column: 2 / -2; }
      }
      &[data-section='3'] .section__article { grid-column: 3 / -3; }
      &[data-section='4'] .section__article .article__para { grid-column: 2 / -2; }
      &[data-section='5'] {
        padding-bottom: 0;
        .background-circles:before { display: none; }
        .section__title {
          grid-column: 3 / -3;
          margin-right: var(--spacing-m);
          margin-left: var(--spacing-m);
        }
        .section__article {
          grid-column: 1 / -1;
          margin-top: var(--spacing-xxl);
          // background-color: var(--color-mainBg);
          // opacity: .7;
          padding: var(--spacing-xxl) var(--spacing-l);
          background-color: rgba(#0e0e0e, 0.7);
          display: grid;
          grid-template-columns: 1fr repeat(var(--grid-col-amount), minmax(auto, var(--grid-col))) 1fr;

          .article__para {
            margin-left: 0;
            grid-column: 3 / -3;
            font-size: 1rem;
            line-height: 2;
            letter-spacing: 0;
          }
        }
      }
      &[data-section='6'] {
        padding:
          var(--spacing-xxl) 0
          calc(var(--spacing-xxl) + var(--spacing-m)) 0
      }
      &[data-section='7'] {
        .section__article--loading-logo {
          .article__para,
          .article__title { grid-column: 2 / -2; }
        }
        .section__article:not(.section__article--loading-logo) {
          grid-column: 2 / -2;
          grid-template-columns: 1fr repeat(var(--grid-col-amount), minmax(auto, var(--grid-col))) 1fr;

          .article__title,
          .article__para { grid-column: 3 / -3; }
        }

        .section__article--prop-ite {
          .article__title,
          .article__para,
          .article__nav {
            grid-column: 3 / -3;
          }
        }

        .article__figure:nth-of-type(1) {
          padding-right: 0;
          padding-left: var(--spacing-m);
          grid-column: 2 / 4;
        }
        .article__figure:nth-of-type(3) {
          padding-right: var(--spacing-m);
          padding-left: 0;
        }
      }

      &[data-section='9'] .section__article--git {
        .article__title,
        .article__para,
        .article__nav {
          grid-column: 2 / -2;
        }
      }

      &[data-section='10'] {
        border-width: var(--spacing-l);
        .section__article .article__para { grid-column: 2 / -2; }
      }
    }
  } // @media only screen and (max-width: #{$bp-desk + 48px})




  @media only screen and (max-width: #{$bp-lap + 48px}) {
    .section {
      --grid-col-amount: 4;
      --container-width: 80%;
      --content-side-spacing-full: var(--spacing-xxl);
      --content-side-spacing-compact: var(--spacing-l);

      &[data-section='1'],
      &[data-section='3'] {
        .section__article { grid-column: 3 / -2; }
      }

      &[data-section='2'],
      &[data-section='4'],
      &[data-section='9'],
      &[data-section='10'] {
        .section__article .article__para {
          grid-column: 1 / -1;
          padding-right: var(--content-side-spacing-full);
          padding-left: var(--content-side-spacing-full);
        }
      }

      &[data-section='5'] {
        .section__title {
          margin-right: 0;
          margin-left: 0;
        }
        .section__article .article__para {
          grid-column: 2 / -2;
          padding-right: var(--content-side-spacing-compact);
          padding-left: var(--content-side-spacing-compact);
        }
      }

      &[data-section='7'] {
        .section__article--loading-logo {
          .article__title,
          .article__para {
            grid-column: 1 / -1;
            padding-right: var(--content-side-spacing-full);
            padding-left: var(--content-side-spacing-full);
          }
        }

        .section__article:not(.section__article--loading-logo) {
          .article__title,
          .article__para,
          .article__nav { grid-column: 2 / -2; }
        }

        .section__article--onboarding {
          grid-column: 2 / -2;
          display: block;
          width: 100%;

          .article__title,
          .article__para {
            padding-right: var(--content-side-spacing-full);
            padding-left: var(--content-side-spacing-full);
          }

          .article__figure {
            padding-right: var(--spacing-s);
            padding-left: var(--spacing-s);
            width: calc(100% / 3);
            float: left;

            &:nth-of-type(1) { padding-left: 0; }
            &:nth-of-type(2) {
              padding-right: var(--spacing-xs);
              padding-left: var(--spacing-xs);
            }
            &:nth-of-type(3) { padding-right: 0; }
          }
        }

      } // &[data-section='7']
    } // .section
  }

  @media only screen and (max-width: #{$bp-tab + 48px}) {
    .section {
      --grid-col-amount: 2;
      --content-side-spacing-full: 0;
      --content-side-spacing-compact: 0;

      &[data-section='1'] {
        .section__title { font-size: 6rem; }
        .section__sub-title { margin-bottom: var(--spacing-m); }
      }

      &[data-section='1'],
      &[data-section='3'] {
        .section__article { grid-column: 2 / -2; }
      }
      &[data-section='5'] .section__title { grid-column: 2 / -2; }

      &[data-section='7'] .section__article:not(.section__article--loading-logo) {
        grid-template-columns: repeat(var(--grid-col-amount), minmax(auto, 2fr));
        .article__title,
        .article__para,
        .article__nav { grid-column: 1 / -1; }
      }

      &[data-section='7'] { padding: var(--spacing-xxl) 0 calc(var(--spacing-xxl) * 2); }
      &[data-section='9'],
      &[data-section='10'] { padding: var(--spacing-xxl) 0 var(--spacing-xxl); }

    }
  }


  @media only screen and (max-width: #{$bp-mob + 48px}) {
    .section {
      --grid-col-amount: 1;

      &[data-section='1'] .section__title { font-size: 4rem; }
      &[data-section='5'] .section__article {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
} // [data-page="akufen"]
