/*
 * Mixin for easy media queries
 * @include bp(tablet) { SCSS here };
*/
@mixin bp($point) {
	@if $point == biggie {
		@media (max-width : 1600px) { @content; }
	}
	@if $point == tablet {
		@media (max-width : 1024px) { @content; }
	}
	@else if $point == phone {
		@media (max-width : 768px) { @content; }
	}
}

/*
 * Mixin for micro clearfix
 * @include microclearfix;
*/
@mixin microclearfix {
	&:before, &:after { content: ""; display: table; }
	&:after { clear: both; }
	*zoom: 1;
}

/*
 * Mixin for basic CSS triangles
 * @include triangle(up, #000, 50px)
*/
@mixin triangle($direction:up, $color:#000, $size:100px) {
	@if($direction == up) {
		border-color: transparent transparent $color;
		border-style: solid;
		border-width: 0 $size $size;
		height: 0;
		width: 0;
	}
	@if($direction == down) {
		border-color: $color transparent transparent transparent;
		border-style: solid;
		border-width: $size;
		height:0;
		width:0;
	}
	@if($direction == left) {
		border-color: transparent $color transparent transparent;
		border-style: solid;
		border-width: $size $size $size 0;
		height: 0;
		width: 0;
	}
	@if($direction == right) {
		border-color: transparent transparent transparent $color;
		border-style: solid;
		border-width: $size 0 $size $size;
		height:0;
		width:0;
	}
}



// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}



@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property:    $property;
  -o-transition-property:      $property;
  -webkit-transition-property: $property;
  transition-property:         $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property:    $duration;
  -o-transition-property:      $duration;
  -webkit-transition-property: $duration;
  transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function:    $timing;
  -o-transition-timing-function:      $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {Keyword | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}



@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};
    @if $i < $max {
      $animations: #{$animations + ", "};
    }
  }
  -webkit-animation: $animations;
  -moz-animation:    $animations;
  -o-animation:      $animations;
  animation:         $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}