.nav__button {
  position: relative;
  float: right;
  display: inline-block;
  font-family: var(--font-titles);
  padding: var(--spacing-xs) var(--spacing-m);
  border: 2px solid var(--color-blue);
  background-color: transparent;
  color: #fff;
  font-weight: 400;
  will-change: color, border-color, background-color;
  overflow: hidden;

  &:focus,
  &:hover {
    color: #fff;
    background: var(--color-blue);
  }
  &:active,
  &:active:after {
    background: var(--color-blue);
  }

  span {
    display: block;
    padding: var(--spacing-xxs) 0 var(--spacing-xs);
    font-family: var(--font-content);
    font-weight: 100;
    font-size: 0.6rem;
    line-height: 1;
    color: rgba(255,255,255,0.4);
  }
}


// Stylish 2000
.nav__button--stripes,
.nav__button--dots,
.nav__button--waves {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 400%;
    height: 400%;
    transform: translateY(-50%);
    user-select: none;
    pointer-events: none;
    opacity: 0;
    z-index: -1;
  }

  &:focus,
  &:hover {
    background: transparent;
    &:after { opacity: 1; }
  }
}


// Moving Stripes on hover
.nav__button--stripes {
  &:after {
    background: repeating-linear-gradient(
      45deg,
      var(--color-blue) 0%,
      var(--color-blue) 20%,
      var(--color-mainBg) 0%,
      var(--color-mainBg) 50%
    );
    background-size: 20px 20px;
  }

  &:focus:after,
  &:hover:after { animation: scrollStripes 800ms linear infinite forwards; }
}


// Moving Dots
.nav__button--dots  {
  --dot-size: 30px;
  &:after {
    background-image:
      radial-gradient(var(--color-blue) 20%, transparent 16%),
      radial-gradient(var(--color-blue) 20%, transparent 16%);
    background-size: var(--dot-size) var(--dot-size);
    background-position: 0 0, calc(var(--dot-size) / 2) calc(var(--dot-size) / 2);
  }

  &:focus:after,
  &:hover:after { animation: scrollDots 4000ms linear infinite forwards; }
}


.nav__button--waves {
  --wave: var(--color-blue);
  --size: 30px;

  &:after {
    background:
      radial-gradient(
        circle at 100% 50%,
        transparent 20%,
        var(--wave) 21%,
        var(--wave) 34%,
        transparent 35%,
        transparent),
      radial-gradient(
        circle at 0% 50%,
        transparent 20%,
        var(--wave) 21%,
        var(--wave) 34%,
        transparent 35%,
        transparent) 0 calc((var(--size) / 2) * -1);

    background-color: var(--color-mainBg);
    background-size: calc((var(--size) / 4) * 3) var(--size);
  }

  &:focus:after,
  &:hover:after { animation: scrollWaves 1200ms linear infinite forwards; }
}




@keyframes scrollStripes {
  from { transform: translateY(-43.5%); }
  to { transform: translateY(0%); }
}

@keyframes scrollDots {
  from { transform: translate(-50%, -50%); }
  to { transform: translate(0%, 0%); }
}

@keyframes scrollWaves {
  from { transform: translateY(-50%); }
  to { transform: translateY(0%); }
}


// TESTING ZONE
