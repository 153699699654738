/* GENERAL */
.show-grid {
  /** Workaround fix for Webkit black-line on retina displays **/
  background-position-y: -1px;
  @include linear-gradient(to bottom, var(--color-fixedLinesBaseline) 1px, rgba(255,255,255,0) 1px);
  background-repeat: repeat-y;
  background-size: 100% 1rem;
}

// Mobile
@media (max-width: 32.99em){
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .header-logo { margin-top: 1rem !important; }
  .button-grid {
    left: 1rem;
    left: calc(1rem + 1px);
  }
}

/****************************************
PAGE TEMPLATES
*****************************************/
/**
 * Table of Contents:
 *
 *  1. Main
 */


/* 1. Main
========================================================================== */
body {
  &:before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    //border: 1rem solid #fff;
    z-index: -1;

    @extend .show-grid;
    @extend %uShallNotClick;

    transition: opacity 0.2s ease-out, transform 0.5s var(--transi-cubicBezier);

    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }

  &[data-lines] .line__horizontal--bottom {
    transition: opacity 0.2s ease-out, transform 0.4s var(--transi-cubicBezier);
    transform: scale(1) translateX(0) !important;
    -webkit-transform: scale(1) translateX(0) !important;
  }

  &[data-lines='visible'] {
    &:before {
      transition-delay: 0.4s;
      transform: translateX(0%);
      -webkit-transform: translateX(0%);
      opacity: 1;
    }

    .line__horizontal--bottom {
      transform: scale(1) translateX(100%) !important;
      -webkit-transform: scale(1) translateX(100%) !important;
    }
  }

}
