/****************************************
  Page - Home
*****************************************/
/**
 * Table of Contents:
 *
 * 0. General
 * 1. Content Text
 * 2. Nav Icons
 * 3. Animations
 */


/* 0. General
========================================================================== */
[data-page="home"] .home__header {
  display: inline-block;
  vertical-align: top; // Get rid ot the bottom gap due to 'inline-block'

  .header__subtitle {
    margin-right: 5px;
    padding-top: 0.15rem;
    display: block;

    color: var(--color-textSub);
    text-align: right;
    font-size: 0.8rem;
    line-height: 1rem;
    overflow: hidden;

    @media (min-width: 48em){ margin-bottom: 0.8rem; }

    span {
      display: inline-block;
    }
  }
}


/* 1. Content
========================================================================== */
.content {
  padding: 0;
  position: relative;
  @media (min-width: 48em){
    width: 50%;
    left: 25%;
  }

  .content__intro,
  .content__cv {
    font-size: 1rem;
    font-weight: 300;
    color: var(--color-textMain);

    em {
      color: var(--color-textSub);
      font-style: normal;
    }
    strong, i { color: var(--color-textMainLight); }
  }

  // 1.1 - Intro
  .content__intro {
    margin-top: 2.75rem;
    @media (min-width: 40em){
      margin-top: 2.7rem;
    }
    @media (min-width: 48em){
      margin-top: 4.95rem;
    }
  }

  // 1.2 - CV Wrapper
  .content__cv {
    display: flex;
    margin-top: 2.3rem;
    justify-content: center;
    line-height: 1;

    @media (min-width: 40em){
      margin-top: 1.2rem;
    }
    @media (min-width: 48em){
      margin-top: 7rem;
      justify-content: end;
    }
  }

  // 1.3 - CV Pre Link
  .content__cv {
    // &__or {

    // }
    .cv__dotdot {
      color: var(--color-red);
      opacity: 0;
      @include animation('dotdot 1s infinite var(--transi-cubicBezier)');

      @for $i from 0 through 2 {
        &[data-dot-id="#{$i}"] {
          animation-delay: $i * 0.1s;
        }
      }
    }
    .cv__link {
      --_link-spacing-y: 0.5rem;
      --_link-spacing-x: 0.5rem;

      position: relative;
      top: calc(var(--_link-spacing-y) * -1);
      margin-left: var(--_link-spacing-x);
      padding: var(--_link-spacing-y) 0;
      color: var(--color-white);
      overflow: hidden;

      // Bottom border
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: calc(100% - (var(--_link-spacing-y) / 2));
        left: 0;
        width: 100%;
        height: 2px;
        background: var(--color-red);
        transition: all 0.6s var(--transi-cubicBezier);
      }

      &::before {
        transform: translateX(-300%);
      }

      &:hover {
        &::before {
          transform: translateX(0);
        }
        &::after {
          transform: translateX(200%);
        }
      }

      &:active {
        color: var(--color-red);
        &::before,
        &::after {
          background: white;
          transition-duration: 0s;
          transition-delay: 0;
        }
      }
    }
  }
  @keyframes dotdot {
    0%, 100% { opacity: 0; }
    25%, 75% { opacity: 1; }
  }

  // 1.4 - CV Link
}




/* 2. Nav Icons
========================================================================== */
.home__social {
  position: absolute;
  right: 2rem;
  bottom: 1.5rem;
  ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  ul li {
    display: block;
    margin-left: 0 !important;
    float: left;

    .social-icon {
      padding: 1rem 10px;
      background: none;
      text-shadow: none;
      display: block;
      color: rgba(255,255,255,0.2);
      font-size: 1rem;
      line-height: 1rem;
      transition: all 0.4s ease-out;
      text-decoration: none;
      i {
        font-style: initial !important;
      }
    }

    &:focus,
    &:hover {
      opacity: 1 !important;
      .social-icon {
        color: #fff !important;
        transition: all 0.2s ease-out;
      }
    }
  }
}




/* 3. Animations
========================================================================== */
// Hide
[data-page="home"] {
  .header__subtitle span,
  .content__intro,
  .content__main-link,
  .content__cv,
  .content__cv .cv__link,
  .home__social li,
  .button-grid {
    transition: all 0.4s var(--transi-cubicBezier);
    opacity: 0;
  }
  .content__cv,
  .content__cv .cv__link {
    transform: translateX(10px);
  }
  .content__main-link { transform: translate(-50%, 10px); }
  .button-grid { transform: translate(-10px, 0); }
}


// Fade In
[data-page="home"].pace-done {
  .header__logo .letter {
    @include animation('dash 4s ease-out forwards');

    $timer: 0.2s;
    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        -webkit-animation-delay: $i * $timer;
        animation-delay: $i * $timer;
      }
    }
  }
  .content__intro,
  .header__subtitle span,
  .content__main-link,
  .content__cv,
  .content__cv .cv__link,
  .button-grid { opacity: 1; }

  .content__intro,
  .content__cv,
  .content__cv .cv__link,
  .header__subtitle span { transform: translate(0, 0); }

  .content__main-link { transform: translate(-50%, 0); }

  .button-grid { transform: translate(0, 0); }

  // Transition Delay
  .header__subtitle span { transition-delay: 1.8s; }
  .content__intro { transition-delay: 1s; }
  .content__main-link { transition-delay: 1.2s; }
  .button-grid { transition-delay: 1.6s; }
  .home__social li {
    $timer: 0.1s;
    $z: 15;
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        $z: $z + 1;
        -webkit-animation-delay: $z * $timer;
        animation-delay: $z * $timer;
      }
    }
    @include animation('iconOpa 1s ease-out forwards');
  }
  .content__cv {
    transition-delay: 2.5s;
  }
  .content__cv .cv__link {
    transition-delay: 3.5s;
  }
}


// Reset delays
[data-page="home"].pace-done.anim-done {
  .header__logo .letter,
  .header__subtitle span,
  .content__intro,
  .content__main-link,
  .home__social li,
  .button-grid {
    transition-delay: initial !important;
  }
}




@include keyframes(dash) {
  0% {
    stroke-dashoffset: 1000;
    stroke: #fff;
  }
  25% { fill: rgba(0,0,0,0); }
  80% {
    stroke-dashoffset: 0;
    stroke: rgba(0,0,0,0);
    fill: #fff;
  }
  100% {
    stroke: rgba(0,0,0,0);
    fill: #fff;
  }
}
@include keyframes(iconOpa) {
  0% {
    opacity: 0;
    transform: translateY(10px);
    -webkit-transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
