/****************************************
Variables
*****************************************/

$spacings: (
  xxs:  3px,
  xs:   6px,
  s:    12px,
  m:    24px,
  l:    36px,
  xl:   48px,
  xxl:  60px
);

// Colors
$colors: (
  mainBg: #0e0e0e,
  mainBorder: #fff,
  blockBg: #101010,

  iconLineBig: #555,
  iconLineSmall: #888,
  buttonLine: #666,
  fixedLines: #404040,
  fixedLinesBaseline: #222,

  textMain: #ededed,
  textMainLight: #fff,
  textSub: #555,
  textLightMain: #333,

  cyan: #00ffff,
  magenta: #ff00ff,
  red: #fc2434,
  blue: #0015eb,
  yellow: #f3fd1b,
  yellow-background: #f1c40f,
  bgWhite: #ffffff
);


$font-stack:
  Frutiger,
  "Frutiger Linotype",
  Univers, Calibri,
  "Gill Sans",
  "Gill Sans MT",
  "Myriad Pro",
  Myriad,
  "DejaVu Sans Condensed",
  "Liberation Sans",
  "Nimbus Sans L",
  Tahoma,
  Geneva,
  "Helvetica Neue",
  Helvetica,
  Arial,
  sans-serif;


$var-maps: (
  spacing: $spacings,
  color: $colors,
);

// Root Declarations
:root {
  --font-titles: "futura-pt", #{$font-stack};
  --font-content: "Roboto Mono", #{$font-stack};

  --transi-cubicBezier: cubic-bezier(.39,.01,.2,.98); /* Cubic-bezier is life. */

  // for each variable map
  @each $var-map-name, $var-map in $var-maps {
    // for each variable in the variable map
    @each $var, $val in $var-map {

      @if type-of($val) == map {
        @each $var-n, $val-n in $val {
          --#{$var-map-name}-#{$var}-#{$var-n}: #{$val-n};
        }
      } @else {
        --#{$var-map-name}-#{$var}: #{$val};
      }
    }
  }

}
