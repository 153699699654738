.js-imageOverlay {
  display: inline-block;
  position: relative;
  font-weight: 600;
}

.js-imageOverlayContainer {
  & > * { display: none; }

  figure {
    position: absolute;
    max-width: 320px;
    border: var(--spacing-s) solid #fff;
    z-index: 10;
  }

  figcaption {
    position: absolute;
    bottom: 0px;
    padding: var(--spacing-xs) var(--spacing-s);
    width: 100%;
    color: #fff;
    font-family: var(--font-titles);
    font-size: 1.4rem;
    font-weight: 400;
    text-shadow: 0px 1px 9px rgba(0,0,0,0.4);
    text-align: center;

    i { font-style: italic; }

    &.no-img {
      position: relative;
      background-color: rgba(0,0,0,0.8);
      font-size: 1.2rem;
      text-align: left;
    }
  }
}
