// TODO: Refactor / Clean

.content__main-link {
  $gap: 8px;
  $extraSpace: 8px;
  $btHeight: 50px;
  $btWidth: 275px;

  position: relative;
  margin: auto;
  left: 50%;
  margin-top: 2.6rem;
  transform: translateX(-50%);

  background-color: var(--color-mainBg);
  border-left: $extraSpace solid var(--color-mainBg);
  border-right: $extraSpace solid var(--color-mainBg);

  width: $btWidth;
  height: $btHeight;
  line-height: $btHeight;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 300;
  display: inline-block;
  text-decoration: none;
  color: var(--color-textMain);
  padding: 0;

  transition: all 0.15s ease-out;
  will-change: color, background-color;

  @media (max-width: 32.99em){
    width: 80%;
    max-width: 275px;
  }
  @media (min-width: 40em){ margin-top: 3.8rem; }
  @media (min-width: 48em){ margin-top: 4.85rem; }
  @media (min-width: 64em){ margin-top: 5.2rem; }

  &:before,
  &:after {
    position: absolute;
    line-height: #{48px - ($gap * 2)};
    vertical-align: middle;
    top: 0;
    left: 0;
    transition: all 0.4s ease-out;
    will-change: opacity, transform;
    opacity: 0;
  }

  // White BG
  &:before {
    content: '';
    width: 100%;
    height: 3rem;
    margin-top: 0.5rem;
    background: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.6);
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    //transform: scale(1);

    @media (min-width: 40em){ margin-top: 0.3rem; }
    @media (min-width: 64em){ margin-top: 0rem; }
  }
  // Text
  &:after {
    content: attr(data-content);
    width: 100%;
    margin-top: 0.4rem;
    line-height: 3rem;
    text-align: center;
    color: #fff;
    font-weight: 300;
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);

    @media (min-width: 40em){ margin-top: 0.25rem; }
    @media (min-width: 48em){ margin-top: 0.2rem; }
    @media (min-width: 64em){ margin-top: -0.15rem; }
  }

  span {
    &:before,
    &:after {
      content: '';
      @extend %uShallNotClick;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: all 0.2s ease-out;
      will-change: border-color, transform;
    }

    // Left - Right
    &:before {
      width: calc(100% + #{$extraSpace * 2});
      margin-top: 0px;
      margin-left: #{-$extraSpace};
      border-left: 1px solid var(--color-buttonLine);
      border-right: 1px solid var(--color-buttonLine);
    }

    // Top - Bottom
    &:after {
      height: calc(100% + #{$extraSpace * 2});
      margin-top: #{-$extraSpace};
      border-top: 1px solid var(--color-buttonLine);
      border-bottom: 1px solid var(--color-buttonLine);
    }
  }

  svg {
    width: calc(100% + 16px);
    position: absolute;
    top: #{- $extraSpace};
    left: #{- $extraSpace};
    display: block;
    transform-origin: center center;

    line {
      stroke-width: 1;
      stroke: var(--color-buttonLine);
      transition: all 0.4s ease-out;
      will-change: opacity, transform, stroke-dashoffset, stroke-dasharray;

      &.link-line__top,
      &.link-line__bottom {
        stroke-dashoffset: 0;
      }
      &.link-line__left { stroke-dasharray: 8 50 10; }
      &.link-line__right {
        stroke-dasharray: 8 50 10;
        transform: translateX(-9px);
      }
    }
  }


  &:focus,
  &:hover {
    color: rgba(255,255,255,0);
    background-color: transparent;

    transition: all 0.2s ease-out;
    &:before,
    &:after {
      transition: all 0.4s ease-out;
      opacity: 1;
    }
    // White BG
    &:before {
      transition-delay: 0.5s;
      transform: scale(1) translateY(-1px);
    }
    // Text
    &:after {
      transition-delay: 0.4s;
      transform: translateY(1px);
    }

    span {
      &:before,
      &:after {
        border-color: rgba(255,255,255,0.4);
      }
      &:before { transform: scale(1, 0); }
      &:after { transform: scale(0, 1); }
    }

    line.link-line {
      &__top {
        transform: translateX(400px);
        transition-delay: 0.3s;
      }
      &__bottom {
        transform: translateX(-400px);
        transition-delay: 0.3s;
      }
      &__right,
      &__left {
        stroke-dashoffset: 92;
        stroke-dasharray: 50;
        opacity: 0;
        transition-delay: 0.5s;
      }
    }
  }
  &:active {
    &:before {
      transition: none;
      background: rgba(255,255,255,1);
    }
    &:after {
      transition: none;
      font-weight: 400;
      color: #000;
    }
  }
}
