/****************************************
  Lines
*****************************************/
/**
 * Table of Contents:
 *
 * 1. Lines
 * 2. Button
 *
 */


/* 1. Lines
========================================================================== */
.decolines {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  @extend %uShallNotClick;

  // _shame.scss

  .line {
    @media (min-width: 33em){
      &__vertical--2 { left: 2rem !important; }
      &__vertical--98 { right: 2rem !important; }
      &__horizontal--top { top: 2rem !important; }
      &__horizontal--bottom { bottom: 2rem !important; }
    }
  }
}

.decolines--fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  @extend %uShallNotClick;
}

.decoline { position: absolute; }



/* 2. Button
========================================================================== */
.button-grid {
  position: absolute;
  left: 2rem;
  left: calc(2rem + 1px);
  bottom: 1.9rem;

  padding: 0;
  height: 2.2rem;
  width: 2rem;
  border: none;
  background: none;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;

  &__icon {
    padding-top: 1px;
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
  }

  .icon__line {
    stroke-width: 1;
    transition: all 0.15s ease-out;
    &--big {
      stroke: var(--color-iconLineBig);
      overflow: hidden;
      &:nth-child(1),
      &:nth-child(3) {
        stroke-dasharray: 40px;
        stroke-dashoffset: 32px;
      }
      &:nth-child(2) {
        stroke-dasharray: 20px;
        stroke-dashoffset: 0px;
      }
    }
    &--small {
      stroke: var(--color-iconLineSmall);
      stroke-dasharray: 16px;
      stroke-dashoffset: 32px;
    }
  }
}

body[data-lines='visible'] .button-grid,
.button-grid:focus { outline: 0; }
.button-grid:hover {
  cursor: pointer;
  .icon__line--big {
    stroke: rgba(255,255,255,0.6);
    width: 100%;
    &:nth-child(1),
    &:nth-child(3) {
      stroke-dashoffset: 0;
    }
  }
  .icon__line--small {
    stroke: rgba(255,255,255,0.3);
    stroke-dashoffset: 0;
  }
}
