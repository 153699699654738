%pulse {
  // canvas dimensions
  $w: 100%; $h: $w;
  // diameter of center dot
  $dot-di: 12px;
  $dot-rad: $dot-di;
  // shadows to generate
  $loop-count: 100;

  // colors
  $c-light: var(--color-mainBg);
  $c-dark: var(--color-blue);

  // removing default margin
  margin: 0;
  // centering canvas on screen
  position: fixed;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  width: $w; height: $h;
  // hiding excess
  overflow: hidden;

  // dot is after
  &:after {
    content: "";
    // position dot in center of canvas
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: $dot-di; height: $dot-di;
    // make a circle
    border-radius: $dot-di / 2;
    // circle color is dark
    background: $c-dark;
    // calling box-shadow animation
    animation: pulse 5000ms linear infinite;
    will-change: box-shadow;
  }

  // how many white lines to influence per full pulse
  $infl-count: 7;
  // influence step
  $infl-step: 0.2;

  $shadows: ();
  // outer loop for each circle.
  // will generate shadow per animation step
  @for $i from 1 through $loop-count + ($infl-count * 2) {
    $shadow: ();
    // creating the actual shodow
    @if $i % 2 == 1 {
      @for $l from 1 through $loop-count {
        // color
        $c: undefined;
        // size
        $s: undefined;
        // if a light color
        @if $l % 2 == 1 {
          $c: $c-light;
          // this could be simplified...
          // current light color
          @if $l == $i {
            $s: $l * $dot-rad + ($dot-rad * $infl-step * 1);
          // previous light color
          } @else if $l == $i - 2 {
            $s: $l * $dot-rad + ($dot-rad * $infl-step * 2);
          } @else if $l == $i - 4 {
            $s: $l * $dot-rad + ($dot-rad * $infl-step * 3);
          } @else if $l == $i - 6 {
            $s: $l * $dot-rad + ($dot-rad * $infl-step * 4);
          } @else if $l == $i - 8 {
            $s: $l * $dot-rad + ($dot-rad * $infl-step * 3);
          } @else if $l == $i - 10 {
            $s: $l * $dot-rad + ($dot-rad * $infl-step * 2);
          } @else if $l == $i - 12 {
            $s: $l * $dot-rad + ($dot-rad * $infl-step * 1);
          // standard light color
          } @else {
            $s: $l * $dot-rad;
          }
        // standard dark color
        } @else {
          $c: $c-dark;
          $s: $l * $dot-rad;
        }
        $shadow: append($shadow, 0px 0px 0px $s $c, comma);
      }
      $shadows: append($shadows, ($shadow), comma);
    }
  }

  // set box shadow to first shadow
  &:after { box-shadow: nth($shadows,1); }

  // this should match $loop-count / 2
  $shadow-count: length($shadows);
  // pulse animation
  @keyframes pulse {
    @for $s from 1 through $shadow-count {
      #{$s/$shadow-count * 100}% { box-shadow: nth($shadows,$s); }
    }
  }
}
