/****************************************
  Logo
*****************************************/
/**
 * Table of Contents:
 *
 * 0. General
 * 1.
 *
 */

.header__logo {
  display: block;
  margin-top: 2rem;
  margin-bottom: 0.8rem;
  height: 4.3rem;
  width: 19rem;

  .letter {
    fill: none;
    stroke-width: 1;
    stroke: #fff;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }
}