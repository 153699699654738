/*
 * Credits:
 *
 * Author: Alexis Bertin
 * Author URI: http://alexisbertin.bzh
 * License: GNU General Public License v2.0
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 * Version: 0.1
 */

/*
 * SASS Architecture:
 *
 * vendor/     - contains libraries
 * utils/      - contains global mixins, functions, helper selectors, etc.
 * base/       - contains global styles, such as resets, typography, colors, etc.
 * components/ - contains each self-contained component in its own .scss partial
 * pages/      - contains page-specific styling, if necessary
 * _shame.scss
 * main.scss   – output file that brings together all of the above part
 */

/*
 * NOTE:
 *
 *
 */

/* External Import */
$fa-font-path: "../bower_components/font-awesome/fonts";
@import "../bower_components/font-awesome/scss/font-awesome";


// Settings
@import "1-settings/variables";

// Utils
@import "2-utils/mixins";
@import "2-utils/normalize";
@import "2-utils/extends";

// Vendor
@import "3-vendor/pace";
@import "3-vendor/sassline-base";

// Base
@import "4-base/global";
@import "4-base/grid";
@import "4-base/page";

// Components
@import "5-components/js-events";
@import "5-components/js-imageOverlay";
@import "5-components/lines";
@import "5-components/logo";
@import "5-components/main-link";
@import "5-components/nav-button";
@import "5-components/pulse";

// Pages
@import "6-pages/landing";
// @import "6-pages/content";
@import "6-pages/home";

// Other
@import "shame";
