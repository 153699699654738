/****************************************
  SHAME SHAME SHAME
*****************************************/
/**
 * The purpose of the _shame.scss is to regroup every 'technical debts',
 * every parts of CSS that aren't well optimized/writen and which will need
 * a refactoring.
 */

/*
  components/_lines.scss
 !important to override JS inline styles declaration
*/
.line {
  padding: 0;

  &__vertical--25,
  &__vertical--50,
  &__vertical--75 {
    opacity: 0 !important;
    transition: all 0.2s var(--transi-cubicBezier);
  }

  &__vertical--25 {
    transform: scale(1) translateY(-100%) translateX(1rem) !important; // Override inline styles
  }
  &__vertical--50 {
    transform: scale(1) translateY(-100%) !important;
  }
  &__vertical--75 {
    transform: scale(1) translateY(-100%) translateX(-1rem) !important; // Override inline styles
  }
}


body[data-lines='visible'] .line {
  @media (min-width: 33em){
    &__vertical--50 {
      opacity: 1 !important;
    }
  }

  @media (min-width: 48em){
    &__vertical--25,
    &__vertical--75 {
      opacity: 1 !important;
    }
  }

  &__vertical--25 {
    transition-delay: 0.1s;
    transform: scale(1) translateY(0) translateX(1rem) !important;
  }
  &__vertical--50 {
    transition-delay: 0.2s;
    transform: scale(1) translateY(0) !important;
  }
  &__vertical--75 {
    transition-delay: 0.3s;
    transform: scale(1) translateY(0) translateX(-1rem) !important;
  }

  &__horizontal--bottom {
    transition: opacity 0.2s ease-out;
    opacity: 0 !important;
  }
}




// DEV Hide Names - NSFW
// .home__header { display: none !important; }
// .content__intro { opacity: 0.2 !important; }
// .content__main-link { color: rgba(0,0,0,0); }
//
// body.haxor {
//   background: url('../images/hackerman.gif') repeat;
//   &:after {
//     content: '';
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     background: rgba(0,0,0,0.6);
//     z-index: 10;
//   }
// }





.glitch {
  &:before,
  &:after {
    content:attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }

  &:before {
    color: var(--color-cyan);
    z-index: -1;
    animation: glitchy 0.3s ease infinite;
  }

  &:after {
    color: var(--color-magenta);
    z-index: -2;
    animation: glitchy 0.3s ease infinite reverse;
  }
}

@keyframes blinkTextCursor{
  from { background-color: #fff; }
  to { background-color: transparent; }
}
@keyframes glitchy {
    0%   {transform: translate(-2px, 2px);}
    25%  {transform: translate(-2px, -2px);}
    50%  {transform: translate(2px, 2px);}
    75%  {transform: translate(2px, -2px);}
    100%  {transform: translate(-2px, 2px);}
}


[data-page='home'] .content {
  transform: translateY(0px);
  opacity: 1;
  user-select: initial;
  pointer-events: initial;
  transition: all 0.3s cubic-bezier(.39,.01,.2,.98);

  &[data-state='hidden'] {
    transform: translateY(-10px);
    opacity: 0;
    user-select: none;
    pointer-events: none;
    transition: all 0.2s cubic-bezier(.39,.01,.2,.98);
  }
}
