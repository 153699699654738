// Akufen Events
.js-events {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  text-align: center;
  color: #fff;

  &:after {
    content: '';
    top: 7px;
    margin-left: 2px;
    position: relative;
    display: inline-block;
    background: #fff;
    width: 1px;
    height: 24px;

    animation: blinkTextCursor 0.6s infinite cubic-bezier(.39,.01,.2,.98);
  }

  p {
    position: relative;
    padding-top: 0;
    display: inline-block;
    z-index: 1;
  }
}
